import axios from "axios";
import { clientOnly$, serverOnly$ } from "vite-env-only/macros";

export function getFrontendUrl() {
  return `${
    serverOnly$(process.env.PUBLIC_URL) || clientOnly$(window.location.origin)
  }`;
}

export const apiClient = axios.create({
  baseURL: `${getFrontendUrl()}/backend/api/`,
  headers: {
    "Content-type": "application/json",
  },
});

export const constructHeaders = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  };
};

export function queryFn<T>(method: string, url: string, access_token?: string) {
  if (access_token) {
    return async () => {
      const headers = constructHeaders(access_token);
      const response = await apiClient.request<T>({
        url,
        method,
        headers,
      });
      return response.data;
    };
  } else {
    return async () => {
      const response = await apiClient.request<T>({
        url,
        method,
      });
      return response.data;
    };
  }
}
