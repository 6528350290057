import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { Link } from "@remix-run/react";
import { IconPlus } from "@tabler/icons-react";
import { cn } from "~/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 focus:outline-none",
  {
    variants: {
      variant: {
        default:
          "bg-primary-foreground text-white hover:bg-primary-foreground/80",
        light: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive-foreground/90 text-white hover:bg-destructive-foreground/70",
        outline:
          "border-[1px] border-primary-foreground text-primary-foreground bg-background hover:bg-primary/50",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-8 px-4 rounded-lg  py-2",
        sm: "h-7 rounded-md px-2 py-1 text-xs",
        md: "h-9 rounded-md text-md px-4",
        lg: "h-10 rounded-md text-lg px-8",
        xl: "h-12 rounded-md text-xl px-10",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";
export interface LinkButtonProps extends ButtonProps {
  href: string;
  fullwidth?: boolean;
  prefetch?: boolean;
}
const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ href, fullwidth, className, children, ...props }, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        className={cn(fullwidth ? "w-full" : "", className)}
        asChild
      >
        <Link to={href} className="w-fit">
          {children}
        </Link>
      </Button>
    );
  }
);
LinkButton.displayName = "LinkButton";

function CreateButton({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return (
    <Button className={cn("px-4 pl-2", className)} onClick={onClick}>
      <IconPlus size={"20px"} className="mr-1" />
      {children}
    </Button>
  );
}

export { Button, buttonVariants, CreateButton, LinkButton };
